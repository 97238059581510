import React from 'react';
import styled from 'styled-components';
import PageGallery from '../queries/page-gallery';
import SiteLayout from '../components/SiteLayout';
import { BannerImageText } from '../components/common/';
import Blogs from '../components/page/BlogPage/Blogs';
import { flattenStrapiData } from '../utils/formatter';
import { useScrolldown } from '../hooks/useScrolldown';

const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Gallery() {
  useScrolldown('#banner_wrapper');
  const renderContent = ({ strapiPageGallery, allStrapiBlog: galleryData }) => {
    const { banner_text } = strapiPageGallery;
    return (
      <ProductWrapper>
        <BannerImageText banner_text={banner_text} />
        <Blogs blogData={flattenStrapiData(galleryData)} />
      </ProductWrapper>
    );
  };
  const LAYOUT_KEY = 'BLOG';
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <PageGallery renderContent={renderContent} />
    </SiteLayout>
  );
}
