import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query PageGallery {
    strapiPageGallery {
      banner_text {
        title
        description
        background {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
    allStrapiBlog(filter: { blog_tags: { elemMatch: { name: { eq: "Bảo tàng" } } } }) {
      edges {
        node {
          id
          slug
          blog_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          title
          description
        }
      }
    }
  }
`;

export default function PageBlog({ renderContent }) {
  return <StaticQuery query={query} render={(data) => renderContent(data)} />;
}
